<template>
  <svg
    class="fill-current"
    width="10"
    height="10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 9a1 1 0 0 0 2 0V6h3a1 1 0 1 0 0-2H6V1a1 1 0 1 0-2 0v3H1a1 1 0 0 0 0 2h3v3Z"
    />
  </svg>
</template>
