<template>
  <div class="flex rounded-full">
    <BaseButton
      :class="props.buttonClass"
      :disabled="disabled || props.modelValue === 1"
      @click="decrement"
    >
      <SvgGlyphMinus />
    </BaseButton>
    <input
      :disabled="disabled"
      :max="max"
      :value="props.modelValue"
      class="w-auto min-w-0 flex-grow bg-transparent text-center focus:outline-none disabled:text-black/25"
      type="number"
      @input="onInput"
    />
    <BaseButton
      :class="props.buttonClass"
      :disabled="disabled || props.modelValue >= max"
      @click="increment"
    >
      <SvgGlyphPlus />
    </BaseButton>
  </div>
</template>

<script lang="ts" setup>
interface InputQuantityProps {
  buttonClass?: string
  disabled?: boolean
  max?: number
  modelValue: number
}

const props = withDefaults(defineProps<InputQuantityProps>(), {
  buttonClass: '',
  disabled: false,
  max: 9999,
})

const emit = defineEmits<{
  'update:modelValue': [value: number]
}>()

function onInput($event: Event) {
  const { value } = $event.target as HTMLInputElement

  if (value) {
    emit('update:modelValue', parseInt(value, 10))
  }
}

function decrement() {
  emit('update:modelValue', props.modelValue - 1)
}

function increment() {
  emit('update:modelValue', props.modelValue + 1)
}
</script>

<style lang="postcss" scoped>
button {
  @apply flex-none rounded-full;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  @apply m-0 appearance-none;
}

input[type='number'] {
  -moz-appearance: textfield;
}
</style>
