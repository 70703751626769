import type { CartFragment, GetCartQuery } from '@/shopify-storefront'
import { GetCartDocument } from '@/graphql/shopify/storefront'

export const useCart = () => {
  const cartStore = useCartStore()
  const cart = ref<CartFragment | null>(null)

  onMounted(() => {
    const { onResult } = useQuery<GetCartQuery>(
      GetCartDocument,
      () => ({ id: cartStore.cartId }),
      () => ({
        clientId: 'shopify',
        enabled: !!cartStore.cartId,
      }),
    )

    onResult(({ data, loading }) => {
      if (data?.cart) {
        cart.value = data.cart
      } else if (!loading) {
        cartStore.cartId = ''
      }
    })
  })

  return cart
}
