<template>
  <svg
    class="fill-current"
    width="10"
    height="10"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M0 5a1 1 0 0 1 1-1h8a1 1 0 1 1 0 2H1a1 1 0 0 1-1-1Z" />
  </svg>
</template>
